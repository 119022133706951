<template>
    <v-row no-gutters>
        <v-col cols="12">
            <AccountBar :organizationId="$route.params.organizationId" :account="account" class="mb-6" v-if="account"></AccountBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pa-0" v-if="refund">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Refund</v-app-bar-title>
                            <v-spacer/>
                            <span style="font-size: 0.7em;" class="text-end">Account ID: {{ accountIdText }}<br>Refund ID: {{ refundIdText }}</span>
                            <!-- TODO: enable invite new users only for organizations with business or enterprise add-ons; organizations on the essential plan have only one admin -->
                            <!-- <v-btn icon :to="{ name: 'organization-invite-user', params: { organizationId: this.$route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>
                        <v-card-text>
                            <p>Created on {{ formatDate(refund.created_on) }}</p>
                            <p>Amount {{ formatAmount(refund.currency, refund.amount_csu) }}
                                <span v-if="['paid'].includes(refund.status)" class="font-weight-bold">Paid</span>
                            </p>
                            <template v-if="refund.status === 'pending'">
                                Pending
                            </template>
                            <v-simple-table>
                                <template #default>
                                    <thead>
                                        <tr>
                                            <th>Product ID</th>
                                            <th>Name</th>
                                            <th>Qty</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, idx) in refund.content" :key="idx">
                                            <td>
                                                {{ item.product_id }}
                                            </td>
                                            <td>
                                                {{ item.name }}
                                            </td>
                                            <td>
                                                {{ item.quantity }}
                                            </td>
                                            <td>
                                                {{ formatAmount(item.currency, item.subtotal_csu) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
import AccountBar from '@/components/AccountBar.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';

function formatId(input) {
    const parts = [];
    let i = 0;
    while (i < input.length) {
        const part = input.substr(i, 5);
        parts.push(part);
        i += 5;
    }
    return parts.join('-');
}

export default {
    components: {
        AccountBar,
        AccessDeniedOverlay,
    },
    data: () => ({
        organization: null,
        refund: null,
        error: null,
        forbiddenError: false,
    }),
    computed: {
        ...mapState({
            organization: (state) => state.organization,
        }),
        refundIdText() {
            return formatId(this.refund.id);
        },
        accountIdText() {
            return formatId(this.refund.account_id);
        },
        account() {
            return this.refund?.account;
        },
    },
    methods: {
        async loadRefund() {
            try {
                this.$store.commit('loading', { loadRefund: true });
                const response = await this.$client.organization(this.$route.params.organizationId).accountRefund.get({ id: this.$route.params.refundId, include: 'account' });
                if (response) {
                    this.refund = response;
                } else {
                    this.refund = null;
                }
            } catch (err) {
                console.error('loadRefund failed', err);
            } finally {
                this.$store.commit('loading', { loadRefund: false });
            }
        },
        viewRefundLink(item) {
            return { name: 'organization-view-refund', params: { organizationId: this.$route.params.organizationId, refundId: item.id } };
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
        formatAmount(currency, amountCSU) {
            return fromCurrencyAmountCSU(currency, amountCSU).toStringWithCurrencySymbol();
        },
        refundStatusDisplay(status) {
            if (status === 'paid') {
                return 'Paid';
            }
            if (status === 'pending') {
                return 'Pending...';
            }
            return null;
        },
    },
    mounted() {
        this.loadRefund();
    },
};
</script>
